import { Fragment } from "react";
import { AlertTriangle, Check, X } from "react-feather";
import Avatar from "@components/avatar";

export const ErrorToast = ({ title = "Erro", message = "" }) => (
    <Fragment>
        <div className="toastify-header">
            <div className="title-wrapper">
                <Avatar size="sm" color="danger" icon={<X size={12} />} />
                <h6 className="toast-title">{title}!</h6>
            </div>
        </div>
        <div className="toastify-body">
            <span role="img" aria-label="toast-text">
                {message}
            </span>
        </div>
    </Fragment>
);

export const WarningToast = ({ title = "Alerta", message = "" }) => (
    <Fragment>
        <div className="toastify-header">
            <div className="title-wrapper">
                <Avatar
                    size="sm"
                    color="warning"
                    icon={<AlertTriangle size={12} />}
                />
                <h6 className="toast-title">{title}!</h6>
            </div>
        </div>
        <div className="toastify-body">
            <span role="img" aria-label="toast-text">
                {message}
            </span>
        </div>
    </Fragment>
);

export const SuccessToast = ({ title = "Sucesso", message = "" }) => (
    <Fragment>
        <div className="toastify-header">
            <div className="title-wrapper">
                <Avatar size="sm" color="success" icon={<Check size={12} />} />
                <h6 className="toast-title">{title}!</h6>
            </div>
        </div>
        <div className="toastify-body">
            <span role="img" aria-label="toast-text">
                {message}
            </span>
        </div>
    </Fragment>
);
