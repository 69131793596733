// ** Logo
import logo from '@src/assets/images/logo/nccicon.png'
import api from '../../../services/api';
import { useEffect } from "react";
import { useCookies } from "react-cookie";

const SpinnerComponent = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  useEffect(async () => {
      try {
          const response = await api.post("/auth/refresh-token");
          const user = response.data.data;
          /*
		 	if (
              !String(window.location.pathname).includes("restrict") &&
              user.isParceiro
          ) {
              await api.post("auth/logout");
              removeCookie("user");
              document.location.href = "/not-authorized";
              return;
          } 
		  */
          setCookie("user", user.userId, {
              path: "/",
              maxAge: 28800,
          });
      } catch (error) {
          removeCookie("user");
      }
  }, []);
  return (
    <div className='fallback-spinner vh-100 d-flex justify-content-center align-items-center'>
      <img className='fallback-logo' width="65" height="65" src={logo} alt='logo' />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
