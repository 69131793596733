import axios from "axios";
import { WarningToast } from "../views/components/alerts/Toast";
import { toast } from "react-toastify";

const url = process.env.REACT_APP_SERVER || "http://localhost:3000";
let isAlreadyFetchingAccessToken = false;

const api = axios.create({
    baseURL: url,
    withCredentials: true,
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status == 401) {
                if (!isAlreadyFetchingAccessToken) {
                    api
                        .post("/auth/refresh-token")
                        .then((res) => {})
                        .catch((err) => {
                            localStorage.removeItem("userData");
                            delete_cookie("user");
                        });
                    isAlreadyFetchingAccessToken = true;
                }
            } else if (error.response.status == 503) {
                toast.warning(
                    <WarningToast message="Estamos a enfrentar problemas de conexão" />,
                    {
                        hideProgressBar: true,
                        position: toast.POSITION.TOP_CENTER,
                    }
                );
            }
        }

        return Promise.reject(error);
    }
);

function delete_cookie(name) {
    document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export default api;
